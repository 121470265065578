export default {
  data () {
    return {
      pageShowTooltip: true
    }
  },
  methods: {
    updateTooltip () {
      if (!this.pageShowTooltip || !this?.seriesData?.length) return
      this.myCharts.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.runDataIndex
      })
      this.runDataIndex++
      const lengthl = this.option.series[0].data?.length ?? 12
      if (this.runDataIndex >= lengthl) this.runDataIndex = 0
    },
    // 鼠标进入的时候关闭tooltip，移出后再重新开启
    bindListen (myChart) {
      const that = this
      myChart.on('mouseover', function ({ dataIndex }) {
        that.pageShowTooltip = false
        that.runDataIndex = dataIndex
      })
      myChart.on('globalout', function () {
        that.pageShowTooltip = true
      })
    }
  }
}
