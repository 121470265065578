export default {
  data () {
    return {
      pageShowFixTooltip: false,
      tooltipData: {
        value: '',
        seriesName: '',
        dataIndex: 0
      },
      stopTransition: false // 停止动画
    }
  },
  methods: {
    // 鼠标进入的时候关闭tooltip，移出后再重新开启
    bindListen (myChart) {
      const that = this
      myChart.on('mouseover', function ({ seriesIndex }) {
        that.pageShowFixTooltip = false
        that.stopTransition = true
        that.runDataIndex = seriesIndex === 4 ? 3 : seriesIndex
      })
      myChart.on('globalout', function () {
        const data = that.seriesData[that.runDataIndex] || {}
        that.tooltipData = {
          value: data.value,
          seriesName: data.name,
          dataIndex: that.runDataIndex,
          percent: data.p
        }
        that.stopTransition = false
        that.pageShowFixTooltip = true
      })
    },
    updateTooltipData () {
      if (!this.pageShowFixTooltip) {
        return // pageShowFixTooltip为false时，认为鼠标进入图层，则不在此更新数据
      } else if (!this.seriesData.length) {
        this.pageShowFixTooltip = false
        return
      }
      this.runDataIndex++
      if (this.runDataIndex >= this.seriesData.length) this.runDataIndex = 0
      const data = this.seriesData[this.runDataIndex]
      this.tooltipData = {
        value: data.value,
        seriesName: data.name,
        dataIndex: this.runDataIndex,
        percent: data.p
      }
    }
  }
}
