<template lang="pug">
.home-row.home-center
  .home-col-left.home-box
    .blue-header 收费情况
    FeePayChart.chart-box(ref="feePayChart")
  .home-col-right.home-box
    .blue-header 近12个月收费趋势
    FeePayLineChart.chart-box(ref="feePayLineChart")
</template>

<script>
import FeePayLineChart from '../../charts/FeePayLineChart.vue'
import FeePayChart from '../../charts/FeePayChart.vue'

export default {
  name: 'MainCenter',
  components: {
    FeePayLineChart,
    FeePayChart
  },
  props: {},
  data () {
    return {}
  },
  methods: {
    updateTooltip () {
      this.$refs.feePayChart.updateLeftTooltipData()
      this.$refs.feePayChart.updateRightTooltip()
      this.$refs.feePayLineChart.updateTooltip()
    }
  }
}
</script>

<style lang="sass" scoped>
.home-center
  flex: 1
  margin-top: $--home-padding
  .home-col-left
    grid-column: 1 / 15
  .home-col-right
    grid-column: 15 / 25
  .chart-box
    width: 100%
    height: calc( 100% - 25px )
</style>
