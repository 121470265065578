<template lang="pug">
.full-box
  el-table.target-table(
    v-loading="loading"
    ref="table"
    height="100%"
    :data="data"
    border
    @mouseenter.native="autoScroll(true)"
    @mouseleave.native="autoScroll(false)")
    el-table-column(
      v-for="column in columns"
      :key="column.field"
      :prop="column.field"
      :label="column.label"
      :width="column.width"
      :minWidth="column.minWidth"
      :formatter="column.formatter"
    )
</template>

<script>

export default {
  name: 'TagetTable',
  props: {},
  data () {
    return {
      loading: false,
      data: [],
      columns: [
        {
          label: '水厂',
          field: 'waterWorks',
          minWidth: 140
        },
        {
          label: '取水许可证(万m³/年)',
          field: 'waterIntaking',
          minWidth: 90
        },
        {
          label: '年供水量(万m³/年)',
          field: 'waterSupply',
          minWidth: 90
        },
        {
          label: '占比',
          field: 'percent',
          minWidth: 70
        }],
      scrolltimer: '' // 自动滚动的定时任务
    }
  },
  methods: {
    // 设置自动滚动
    autoScroll (stop) {
      const table = this.$refs.table
      // 拿到表格中承载数据的div元素
      const divData = table.$refs.bodyWrapper
      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      if (stop) {
        // 再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
        window.clearInterval(this.scrolltimer)
      } else {
        this.scrolltimer = window.setInterval(() => {
          // 元素自增距离顶部1像素
          divData.scrollTop += 1
          // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
          if (divData.clientHeight + divData.scrollTop === divData.scrollHeight) {
            // 重置table距离顶部距离
            divData.scrollTop = 0
            // 重置table距离顶部距离。值=(滚动到底部时，距离顶部的大小) - 整个高度/2
            // divData.scrollTop = divData.scrollTop - divData.scrollHeight / 2
          }
        }, 150) // 滚动速度
      }
    },
    getData () {
      this.loading = true
      this.$get({
        url: '/report/usewaterIndex'
      }).then(res => {
        this.loading = false
        if (!res || !res.data) {
          return 'false'
        } else {
          this.data = res.data
          this.$nextTick(() => {
            this.autoScroll()
          })
        }
      }).catch(e => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.getData()
  },
  beforeDestroy () {
    this.autoScroll(true)
  }
}
</script>

<style lang="sass" scoped>
.target-table
  ::v-deep
    th,td
      color: #fff
    tr
      background-color: unset
    td
      border-right: unset
      border-bottom-color: rgba(23, 142, 241, 0.7)
    th
      background-color: rgba(23, 142, 241, 0.3)
      border-bottom: 0
      border-right: unset
      .cell
        white-space: unset
    .el-table__body tr:hover>td
      background-color: unset
</style>
