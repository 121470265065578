<template lang="pug">
.chart-item(
  v-loading="loading"
  element-loading-spinner="el-icon-loading"
  element-loading-background="rgba(0, 0, 0, 0.5)"
  ref="chartItem")
</template>

<script>
import { mapActions } from 'vuex'
import { colors } from '@/utils/chartsHelper.js'
import barLinetooltip from '../mixins/barLineTooltip'

export default {
  name: 'FeePayLineChart',
  inject: ['echarts'],
  mixins: [barLinetooltip],
  props: {},
  data () {
    return {
      loading: true,
      runDataIndex: 0,
      myCharts: null,
      option: {},
      seriesData: [],
      dimension: ['month'],
      waterWorksRelation: [],
      waterWorksOrgRelation: []
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getConfig () {
      return {
        color: colors,
        textStyle: { color: '#fff' },
        tooltip: {
          trigger: 'axis',
          // 点击tips滚动里面内容
          enterable: true,
          extraCssText: 'overflow:scroll;height:60%;',
          axisPointer: {
            type: 'shadow'
          },
          formatter (params) {
            const total = `<br/><span style="display: inline-block; width: 100px">${params[0].marker}合计</span><strong>${params[0].value.total || '-'}</strong>万m³`
            return params.reduce((res, item) => {
              const valueKey = item.dimensionNames[item.encode.y]
              res += `<br/><span style="display: inline-block; width: 100px;">${item.marker}${item.seriesName}</span><strong>${item.value[valueKey]}</strong>万元`
              return res
            }, `${params[0].axisValueLabel}`) + total
          }
        },
        legend: {
          bottom: 0,
          textStyle: { color: '#fff' },
          type: 'scroll', // 图例是否能滚动
          pageTextStyle: {
            color: '#fff'
          },
          pageIconColor: '#fff'
        },
        grid: {
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          name: '(万元)',
          splitLine: { show: false }
        },
        dataset: {
          dimension: this.dimension,
          source: this.seriesData
        },
        series: this.waterWorksRelation.map((item, index) => {
          return {
            keyName: item[0],
            name: item[1],
            type: 'line',
            smooth: true,
            encode: {
              x: 'month',
              y: item[0]
            }
          }
        })
      }
    },
    // 渲染图表
    renderEchart ({ ref }) {
      const dom = this.$refs[ref]
      const config = this.getConfig()
      this.option = config
      this.myCharts = this.echarts.init(dom)
      this.myCharts.setOption(config)
      this.bindListen(this.myCharts)
    },
    renderRelation () {
      this.getRelations(['waterWorks'])
        .then(res => {
          this.waterWorksOrgRelation = res.waterWorks
          this.getData()
        })
    },
    getData () {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.$get({ url: '/report/lastTwelveMonthChargingTrend' })
          .then(res => {
            this.loading = false
            if (!res) {
              reject(res)
            } else {
              const rowKeys = Object.keys(res.data[0]).filter(item => !['month', 'total'].includes(item))
              this.dimension = this.dimension.concat(rowKeys)
              this.seriesData = res.data
              this.waterWorksRelation = rowKeys.map(key => {
                const option = this.waterWorksOrgRelation.find(item => `${item.value}` === key)
                return [key, option.label]
              })
              this.renderEchart({ ref: 'chartItem' })
              resolve(res)
            }
          })
          .catch(e => {
            this.loading = false
            reject(e)
          })
      })
    }
  },
  mounted () {
    this.renderRelation()
  }
}
</script>
