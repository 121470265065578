<template lang="pug">
.chart-box(
  v-loading="loading"
  element-loading-spinner="el-icon-loading"
  element-loading-background="rgba(0, 0, 0, 0.5)")
  .chart-item.full-box(ref="chartItem")
  transition(
    v-if="!stopTransition"
    name="moveCartoon"
    appear)
    TooltipAction.float-box(
      v-show="showFixTooltip && pageShowFixTooltip"
      v-bind="tooltipData"
      danwei="万m³"
    )
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { getPie3D, fontSize } from '@/utils/chartsHelper.js'
import TooltipAction from '../components/TooltipAction'
import pieTooltip from '../mixins/pieTooltip.js'

export default {
  name: 'UsenatureChart',
  inject: {
    echarts: {
      from: 'echarts',
      default: () => ({})
    },
    getShowFixTooltip: {
      from: 'getShowFixTooltip',
      default: () => () => true
    }
  },
  components: { TooltipAction },
  mixins: [pieTooltip],
  props: {},
  computed: {
    showFixTooltip () {
      return this.getShowFixTooltip()
    }
  },
  data () {
    return {
      loading: true,
      runDataIndex: 0,
      seriesData: []
    }
  },
  methods: {
    getConfig () {
      const data = cloneDeep(this.seriesData)
      const option = getPie3D(data, 0.7, {
        distance: 220
      }, '万m³', {
        top: -10
      })
      // 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
      option.series.push({
        name: 'pie2d',
        type: 'pie',
        labelLine: {
          show: false,
          length: 15,
          length2: 15
        },
        startAngle: -fontSize(0.3), // 起始角度，支持范围[0, 360]。
        clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: [fontSize(1.3) + '%', fontSize(1.4) + '%'],
        center: [fontSize(1.5) + '%', fontSize(1.8) + '%'], // 指示线的位置
        data: data,
        itemStyle: {
          opacity: 0
        }
      })
      return option
    },
    // 渲染图表
    renderEchart ({ ref }) {
      const dom = this.$refs[ref]
      const config = this.getConfig()
      this.myCharts = this.echarts.init(dom)
      this.myCharts.setOption(config)
      this.$nextTick(() => {
        this.pageShowFixTooltip = true
        this.updateTooltipData()
        this.bindListen(this.myCharts)
      })
    },
    getData () {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.$get({ url: '/report/usenatureInfo' })
          .then(res => {
            this.loading = false
            if (!res) {
              reject(res)
            } else {
              this.seriesData = res.data || []
              this.renderEchart({ ref: 'chartItem' })
              resolve(res)
            }
          })
          .catch(e => {
            this.loading = false
            reject(e)
          })
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="sass" scoped>
.chart-box
  position: relative
  .float-box
    position: absolute
    right: 10%
    top: 10%
</style>
