import dayjs from 'dayjs'

class TimeClock {
  constructor () {
    this.timer = null
    this.datetime = dayjs().format('dddd YYYY-MM-DD HH:mm:ss')
  }

  start () {
    this.timer = setInterval(() => {
      this.update()
    }, 1000)
    return this
  }

  update () {
    this.datetime = dayjs().format('dddd YYYY-MM-DD HH:mm:ss')
    return this
  }

  end () {
    if (this.timer) clearInterval(this.timer)
    return this
  }
}

export default TimeClock
