import { render, staticRenderFns } from "./MainHeaderItem.vue?vue&type=template&id=b99c58fc&scoped=true&lang=pug"
import script from "./MainHeaderItem.vue?vue&type=script&lang=js"
export * from "./MainHeaderItem.vue?vue&type=script&lang=js"
import style0 from "./MainHeaderItem.vue?vue&type=style&index=0&id=b99c58fc&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b99c58fc",
  null
  
)

export default component.exports