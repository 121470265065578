// import { amoutInterval } from './common'
import round from 'lodash/round'

export const colors = [
  '#5856D6', // 紫
  '#3BDC88', // 绿
  '#F6CB55', // 黄
  '#F56C6C', // 红
  '#00A9EC', // 蓝
  '#ff807e', // 粉
  '#817f41', // 中国绿
  '#ff8040', // 橙
  '#fe0000', // 大红
  '#417f7f', // 蓝绿
  '#038000', // 深绿
  '#f7f776', // 黄
  '#af6738' // 棕
]

export function getPie3D (pieData, internalDiameterRatio, viewControlObj, danwei, grid3DConfig = {}) {
  // internalDiameterRatio:透明的空心占比
  const series = []
  let sumValue = 0
  let startValue = 0
  let endValue = 0
  const k = 1 - internalDiameterRatio
  // pieData.sort((a, b) => {
  //   return b.value - a.value
  // })
  // 为每一个饼图数据，生成一个 series-surface 配置
  for (let i = 0; i < pieData.length; i++) {
    sumValue += pieData[i].value
    const seriesItem = {
      name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
      type: 'surface',
      parametric: true,
      selectedMode: 'single',
      wireframe: {
        show: false
      },
      pieData: pieData[i],
      pieStatus: {
        selected: false,
        hovered: false,
        k: k
      },
      radius: '20%',
      center: ['10%', '20%']
    }
    const itemStyle = {
      opacity: 0.8
    }
    if (typeof colors[i] !== 'undefined') itemStyle.color = colors[i]
    seriesItem.itemStyle = itemStyle

    series.push(seriesItem)
  }
  // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
  // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。

  for (let i = 0; i < series.length; i++) {
    const orgValue = series[i].pieData.value // 原始值
    endValue = round(startValue + orgValue, 4)
    series[i].pieData.orgValue = orgValue
    series[i].pieData.startRatio = round(startValue / sumValue, 4)
    series[i].pieData.endRatio = round(endValue / sumValue, 4)
    series[i].parametricEquation = getParametricEquation(
      series[i].pieData.startRatio,
      series[i].pieData.endRatio,
      false,
      false,
      k,
      20 // 饼图厚度
    )
    startValue = endValue
  }
  // let boxHeight = getHeight3D(series, 15); //通过传参设定3d饼/环的高度，26代表26px
  // 准备待返回的配置项，把准备好的 legendData、series 传入。
  const option = {
    labelLine: {
      show: true,
      lineStyle: {
        color: '#7BC0CB'
      }
    },
    legend: {
      // orient: 'vertical',
      // top: 'center',
      // right: fontSize(0.1),
      bottom: 0,
      data: pieData,
      // icon: 'circle',
      type: 'scroll', // 图例是否能滚动
      pageTextStyle: {
        color: '#fff'
      },
      pageIconColor: '#fff',
      itemWidth: fontSize(0.4), // 矩形宽度
      itemHeight: fontSize(0.2), // 矩形高度
      formatter: (res) => {
        let str = ''
        series.forEach((ele) => {
          if (res === ele.name) {
            str = `{e|${ele.pieData.name}}`
          }
        })
        return str
      },
      textStyle: {
        color: '#fff',
        fontSize: '13px',
        padding: [5, 0],
        rich: {
          a: {
            fontSize: '13px',
            color: '#00DCF6'
            // width: fontSize(0.5),
          },
          b: {
            fontSize: '13px',
            color: '#00DCF6'
            // width: fontSize(0.85),
          },
          e: {
            fontSize: '13px'
            // width: fontSize(1.0),
          }
        }
      }
    },
    label: {
      show: false,
      formatter: ''
    },
    tooltip: {
      trigger: 'item',
      show: true,
      formatter: (params) => {
        if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
          const pieData = option.series[params.seriesIndex].pieData
          const orgValue = pieData.orgValue
          const ponit = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>`
          return `${params.seriesName}<br/>${ponit}${orgValue}${danwei || ''}<br/>${ponit}${pieData.p}%`
        }
      }
    },
    xAxis3D: {
      min: -1,
      max: 1
    },
    yAxis3D: {
      min: -1,
      max: 1
    },
    zAxis3D: {
      min: -1,
      max: 1
    },
    grid3D: {
      show: false,
      boxHeight: 20, // 圆环的高度
      // left: -fontSize(2.2),
      // top: -fontSize(0.1), // 3d饼图的位置
      ...grid3DConfig,
      viewControl: Object.assign({
        alpha: 30, // 角度
        distance: 400, // 调整视角到主体的距离，类似调整zoom
        rotateSensitivity: 1, // 设置为0无法旋转
        zoomSensitivity: 2, // 设置为0无法缩放
        panSensitivity: 0, // 设置为0无法平移
        autoRotate: false // 自动旋转
      }, viewControlObj || {})
    },
    series
  }
  return option
}

// 字体自适应。
export function fontSize (res) {
  const clientWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  if (!clientWidth) return
  const fontSize = 40 * (clientWidth / 1920)
  return res * fontSize
}

export function getParametricEquation (
  startRatio,
  endRatio,
  isSelected,
  isHovered,
  k,
  h
) {
  // 计算
  const midRatio = (startRatio + endRatio) / 2
  const startRadian = startRatio * Math.PI * 2
  const endRadian = endRatio * Math.PI * 2
  const midRadian = midRatio * Math.PI * 2
  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false
  }
  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3
  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0
  const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0
  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  const hoverRate = isHovered ? 1.05 : 1
  // 返回曲面参数方程
  return {
    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },
    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },
    x: function (u, v) {
      if (u < startRadian) {
        return (
          offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
        )
      }
      if (u > endRadian) {
        return (
          offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
        )
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate
    },
    y: function (u, v) {
      if (u < startRadian) {
        return (
          offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
        )
      }
      if (u > endRadian) {
        return (
          offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
        )
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate
    },
    z: function (u, v) {
      if (u < -Math.PI * 0.5) {
        return Math.sin(u)
      }
      if (u > Math.PI * 2.5) {
        return Math.sin(u) * h * 0.1
      }
      return Math.sin(v) > 0 ? 1 * h * 0.1 : -1
    }
  }
}
