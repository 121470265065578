<template lang="pug">
.home-row.home-footer
  .home-col.home-box.usenature-box(col="9")
    .blue-header 今年各用水性质占比
    UsenatureChart.chart-box(ref="usenatureChart")
  .home-col.home-box(col="51")
    .blue-header 今年各用水性质用水量
    WaterByUsenatureChart.chart-box(ref="waterByUsenatureChart")
  .home-col.home-box(col="52")
    .blue-header 今年用水情况占比
    WaterUseStatusChart.chart-box(ref="waterUseStatusChart")
  .home-col.home-box(col="53")
    .blue-header.last 用水指标分析
    TagetTable.table-box
</template>

<script>
import WaterUseStatusChart from '../../charts/WaterUseStatusChart.vue'
import WaterByUsenatureChart from '../../charts/WaterByUsenatureChart.vue'
import UsenatureChart from '../../charts/UsenatureChart.vue'
import TagetTable from './TagetTable.vue'

export default {
  name: 'MainFooter',
  components: {
    WaterUseStatusChart,
    WaterByUsenatureChart,
    UsenatureChart,
    TagetTable
  },
  props: {},
  data () {
    return {
      searchMonth: ''
    }
  },
  methods: {
    updateTooltip () {
      this.$refs.usenatureChart.updateTooltipData()
      this.$refs.waterUseStatusChart.updateTooltipData()
      this.$refs.waterByUsenatureChart.updateTooltip()
    }
  }
}
</script>

  <style lang="sass" scoped>
  .home-footer
    margin-top: $--home-padding
    flex: 1
    min-height: 300px
    max-height: 600px
    .home-col[col="9"]
      grid-column: 1 / 7
    .home-col[col="51"]
      grid-column: 7 / 15
    .home-col[col="52"]
      grid-column: 15 / 19
    .home-col[col="53"]
      grid-column: 19 / 25
    .blue-header.last
      padding-bottom: 8px
    .table-box
      width: 100%
      height: calc( 100% - 36px )
      // margin-top: 8px
    .chart-box
      width: 100%
      height: calc( 100% - 25px )
    .usenature-box
      position: relative
      .fix-right
        position: absolute
        top: $--home-padding
        right: $--home-padding
        width: 100px
  </style>

<style scoped>
.home-row >>> .el-table {
  background-color: unset;
}
.home-row >>> .el-table__expanded-cell {
  background-color: unset;
}
.home-row >>> .el-table--border {
  border: none;
}
.home-row >>> .el-table--border::after, .el-table--group::after {
  height: 0;
}
.home-row >>> .el-table::before {
  height: 0;
}
</style>
