<template lang="pug">
span.tooltip-action(:style="`border-color:${colors[dataIndex]};`")
  span {{ seriesName }}
  br
  span.color-point(:style="`background-color:${colors[dataIndex]};`")
  span {{ `${value}${danwei || ''}` }}
  br
  span.color-point(:style="`background-color:${colors[dataIndex]};`")
  span {{ `${percent}%` }}
</template>

<script>
import { colors } from '@/utils/chartsHelper.js'

export default {
  name: 'TooltipAction',
  props: {
    seriesName: {
      type: String,
      default: ''
    },
    dataIndex: {
      type: Number,
      default: 0
    },
    danwei: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    percent: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      colors
    }
  },
  methods: {}
}
</script>

<style lang='sass' scoped>
.tooltip-action
  background-color: #fff
  border: 1px solid transparent
  padding: 10px
  color: rgb(102, 102, 102)
  border-radius: 4px
  font: 14px / 21px sans-serif
  white-space: nowrap
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 10px
  transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s
  .color-point
    display: inline-block
    margin-right: 5px
    border-radius: 10px
    width: 10px
    height: 10px
</style>
