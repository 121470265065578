<template lang="pug">
.chart-item(
  v-loading="loading"
  element-loading-spinner="el-icon-loading"
  element-loading-background="rgba(0, 0, 0, 0.5)"
  ref="chartItem")
</template>

<script>
import { mapActions } from 'vuex'
import { fontSize } from '@/utils/chartsHelper.js'
import barLinetooltip from '../mixins/barLineTooltip'

export default {
  name: 'WaterByUsenatureChart',
  inject: ['echarts'],
  mixins: [barLinetooltip],
  props: {},
  data () {
    return {
      loading: true,
      runDataIndex: 0,
      myCharts: null,
      option: {},
      seriesData: [],
      dimension: ['month'],
      usenatureRelation: [],
      usenatureOrgRelation: []
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getConfig () {
      return {
        textStyle: { color: '#fff' },
        tooltip: {
          trigger: 'axis',
          // 点击tips滚动里面内容
          enterable: true,
          extraCssText: 'overflow:scroll;height:60%;',
          axisPointer: {
            type: 'shadow'
          },
          formatter (params) {
            const total = `<br/><span style="display: inline-block; min-width: 120px;">${params[0].marker}合计</span><strong>${params[0].value.total}</strong>万m³`
            return params.reduce((res, item) => {
              const valueKey = item.dimensionNames[item.encode.y]
              res += `<br/><span style="display: inline-block; min-width: 120px;margin-right: 8px;">${item.marker}${item.seriesName}</span><strong>${item.value[valueKey]}</strong>万m³`
              return res
            }, `${params[0].axisValueLabel}`) + total
          }
        },
        legend: {
          type: 'scroll', // 图例是否能滚动
          bottom: 0,
          textStyle: { color: '#fff' },
          itemWidth: fontSize(0.4), // 矩形宽度
          itemHeight: fontSize(0.2) // 矩形高度
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          name: '(万m³)',
          splitLine: { show: false }
        },
        grid: {
          // left: 100
          // top: 20,
          // right: 20
        },
        dataset: {
          dimension: this.dimension,
          source: this.seriesData
        },
        series: this.usenatureRelation.map((item, index) => {
          return {
            keyName: item[0],
            name: item[1],
            type: 'bar',
            stack: 'x',
            encode: {
              x: 'month',
              y: item[0]
            }
          }
        })
      }
    },
    // 渲染图表
    renderEchart ({ ref }) {
      const dom = this.$refs[ref]
      const config = this.getConfig()
      this.option = config
      this.myCharts = this.echarts.init(dom)
      this.myCharts.setOption(config)
      this.bindListen(this.myCharts)
    },
    renderRelation () {
      this.getRelations(['usenature'])
        .then(res => {
          this.usenatureOrgRelation = res.usenature
          this.getData()
        })
    },
    getData () {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.$get({ url: '/report/usenatureQuantity' })
          .then(res => {
            this.loading = false
            if (!res) {
              reject(res)
            } else {
              const rowKeys = Object.keys(res.data[0]).filter(item => !['month', 'total'].includes(item))
              this.dimension = this.dimension.concat(rowKeys)
              this.seriesData = res.data
              this.usenatureRelation = rowKeys.map(key => {
                const option = this.usenatureOrgRelation.find(item => `${item.value}` === key)
                return [key, option.label]
              })
              this.renderEchart({ ref: 'chartItem' })
              resolve(res)
            }
          })
          .catch(e => {
            this.loading = false
            reject(e)
          })
      })
    }
  },
  mounted () {
    this.renderRelation()
  }
}
</script>
