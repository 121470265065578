<template lang="pug">
.home-row
  MainHeaderItem(
    v-for="column in columns"
    :key="column.key"
    :style="`grid-column: ${column.gridColumn}`"
    :title="column.title"
    :valueType="column.valueType"
    :fieldList="column.fieldList"
    :data="data"
    :recycleRate="column.recycleRate"
    :dataType="column.dataType"
    :precision="column.precision")
</template>

<script>
import MainHeaderItem from './MainHeaderItem.vue'

export default {
  name: 'MainHeader',
  components: { MainHeaderItem },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    let startColumn = 1
    const columns = [{
      key: 'thisYearFee',
      title: '今年收费情况',
      valueType: 'primary',
      colspan: 6,
      recycleRate: -90,
      dataType: 'money',
      precision: 3,
      fieldList: [
        { name: 'thisYearTotal', label: '应收', suffix: '万元' },
        { name: 'thisTearReceipts', label: '实收', suffix: '万元' }
      ]
    }, {
      key: 'thisMonthFee',
      title: '上月收费情况',
      valueType: 'success',
      colspan: 6,
      recycleRate: 100,
      dataType: 'money',
      precision: 3,
      fieldList: [
        { name: 'lastMonthTotal', label: '应收', suffix: '万元' },
        { name: 'lastMonthReceipts', label: '实收', suffix: '万元' }
      ]
    }, {
      key: 'water',
      title: '用水量',
      valueType: 'warning',
      colspan: 6,
      dataType: 'water',
      precision: 2,
      fieldList: [
        { name: 'waterUse', label: '今年总用水量', suffix: '万m³' },
        { name: 'waterUseLastMonth ', label: '上月总用水量', suffix: '万m³' }
      ]
    }, {
      key: 'unpay',
      title: '欠款情况',
      valueType: 'primary',
      colspan: 3,
      dataType: 'money',
      precision: 3,
      fieldList: [
        { name: 'unpayAmount', label: '总欠款数', suffix: '万元' }
      ]
    }, {
      key: 'usewater',
      title: '用水户数',
      valueType: 'purple',
      colspan: 3,
      dataType: 'normal',
      precision: 0,
      fieldList: [
        { name: 'usewaterCount', label: '总户数', suffix: '户' }
      ]
    }].map(column => {
      const colspan = column.colspan
      const endColumn = startColumn + colspan
      column.gridColumn = `${startColumn} / ${endColumn}`
      // 更新下一个元素的起始列
      startColumn = endColumn
      return column
    })
    return {
      // 一行分成24份
      columns
    }
  },
  methods: {}
}
</script>

<style lang="sass" scoped>

</style>
