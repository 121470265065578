<template lang="pug">
.home-box.status-item
  .status-title
    .blue-header {{ title }}
    .second-title(
      v-if="recycleRate"
      :class="`status-text-${recycleRate > 0 ? 'success' : 'danger'}`")
      i.iconfornt(
        :class="`el-icon-${recycleRate > 0 ? 'top' : 'bottom'}`")
      span.tip-title 收缴率: {{ recycleRate|format }}
  ul.data-box(
    :class="fieldList.length > 1 ? 'grid-box' : ''")
    li.data-item.full-width(
      v-for="field in fieldList"
      :key="field.name"
      :class="valueType")
      label.label-item {{ field.label }}
      .value-item.full-width(:class="`status-text-${valueType}`")
        strong {{ data[field.name]|foramtterMoney({ dataType, precision }) }}
        span.suffix(v-if="field.suffix") {{ field.suffix }}
</template>

<script>
import { amoutInterval, bigNumberTransform, isEmpty } from '@/utils/common.js'

export default {
  name: 'MainHeaderItem',
  filters: {
    format (recycleRate) {
      return `${recycleRate}`.replace('-', '') + '%'
    },
    foramtterMoney (value, { dataType, precision }) {
      if (isEmpty(value)) {
        return '-'
      } else {
        const result = dataType === 'normal' ? value : bigNumberTransform(value, precision, false)
        return amoutInterval(result, precision)
      }
    }
  },
  props: {
    title: {
      type: String,
      default: '标题'
    },
    // 回收率
    recycleRate: {
      type: [Number, String],
      default: null
    },
    // 字段配置
    fieldList: {
      type: Array,
      default: () => ([])
    },
    // 数据源
    data: {
      type: Object,
      default: () => ({})
    },
    // 值的文字颜色类型 success/danger/warning/primary/purple
    valueType: {
      type: String,
      default: ''
    },
    // money/water/normal
    dataType: {
      type: String,
      default: ''
    },
    precision: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="sass" scoped>
.status-text-success
  color: $--home-green
.status-text-danger
  color: $--home-red
.status-text-warning
  color: $--home-yellow
.status-text-primary
  color: $--home-blue
.status-text-purple
  color: $--home-purple
.status-item
  color: #fff
  .status-title
    display: flex
    flex-direction: row
    align-items: center
    .second-title
      .iconfornt
        padding: 0 0.5em
      .iconfornt, .tip-title
        font-size: 13px
  .data-box
    font-size: 0.6em
    margin-top: 1.4em
    &.grid-box
      display: grid
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 1em
      grid-auto-flow: row
    .data-item
      strong
        font-size: 2em
      .suffix
        padding-left: 1em
      &+.data-item
        position: relative
        padding-left: 1em
        &::before
          content: ''
          width: 1px
          height: 80%
          display: block
          position: absolute
          left: -1em
          top: 10%
        &.success::before
          background-color: $--home-green
        &.danger::before
          background-color: $--home-red
        &.warning::before
          background-color: $--home-yellow
        &.primary::before
          background-color: $--home-blue
        &.purple::before
          background-color: $--home-purple
</style>
