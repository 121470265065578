<template lang="pug">
.chart-item(
  v-loading="loading"
  element-loading-spinner="el-icon-loading"
  element-loading-background="rgba(0, 0, 0, 0.5)")
  .org-chart
    .left-chart.full-box(ref="chartLeft")
    transition(
      v-if="!stopTransition"
      name="moveCartoon"
      appear)
      TooltipAction.float-box(
        v-show="showFixTooltip && pageShowFixTooltip && tooltipData.seriesName"
        v-bind="tooltipData"
        danwei="万元")
  .center-box
    el-radio-group.chart-radio(
      v-model="priceType"
      size="mini"
      @change="handleTypeChange")
      el-radio-button(label="1") 应收
      el-radio-button(label="2") 实收
  .month-chart(ref="chartRight")
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { getPie3D, fontSize, colors } from '@/utils/chartsHelper.js'
import TooltipAction from '../components/TooltipAction'
import pieTooltip from '../mixins/pieTooltip.js'

export default {
  name: 'FeePayChart',
  inject: {
    echarts: {
      from: 'echarts',
      default: () => ({})
    },
    getShowFixTooltip: {
      from: 'getShowFixTooltip',
      default: () => () => true
    }
  },
  components: { TooltipAction },
  mixins: [pieTooltip],
  props: {},
  computed: {
    showFixTooltip () {
      return this.getShowFixTooltip()
    }
  },
  data () {
    return {
      loading: true, // 页面loadig
      // ----左图
      runDataIndex: 0, // 左图tooltip轮播数据index
      priceType: '1', // 价格类型 1实收 2应收
      seriesData: [], // 左图数据
      leftChart: { // 左图配置与图表实例
        option: null,
        chart: null
      },
      // ---右图
      rightChart: { // 右图配置与图表实例
        option: null,
        chart: null
      },
      rightShowTooltip: true, // 是否轮播显示右图tooltip
      rightRunDataIndex: 0, // 右图tooltip轮播数据index
      rightSeriesData: [], // 右图数据
      chargingInfo: '/report/chargingInfo', // 收费情况饼图
      lastTwelveMonthChargingInfo: '/report/lastTwelveMonthChargingInfo' // 近十二个月收费情况柱状图
    }
  },
  methods: {
    // 饼图的实收和应收切换数据
    handleTypeChange (value) {
      this.pageShowFixTooltip = false // 关闭tooltip显示
      this.stopTransition = true // 停止动画
      this.seriesData.forEach((item, index) => { // 处理value和占比数据
        let bignumber, p
        if (value === '2') { // 实收
          bignumber = this.seriesData[index].receipts
          p = this.seriesData[index].rp
        } else { // 应收
          bignumber = this.seriesData[index].total
          p = this.seriesData[index].tp
        }
        this.seriesData[index].value = bignumber
        this.seriesData[index].p = p
      })
      const option = this.getChartLeftConfig() // 重新获取配置
      this.leftChart.chart.setOption(option) // 更新配置
      setTimeout(() => {
        this.$nextTick(() => {
          this.stopTransition = false // 开启动画
          this.pageShowFixTooltip = true // 开启tooltip显示
        })
      }, 3000)
    },
    // 饼图配置
    getChartLeftConfig () {
      const data = cloneDeep(this.seriesData)
      const option = getPie3D(data, 0.7, {
        alpha: 180,
        distance: 300
      }, '万元')
      option.title = {
        text: '今年各水厂收费占比',
        left: 'center',
        textStyle: {
          color: '#fff',
          fontSize: '13px',
          fontWeight: 'normal'
        }
      }
      // 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
      option.series.push({
        name: 'pie2d',
        type: 'pie',
        labelLine: {
          show: false,
          length: 15,
          length2: 15
        },
        startAngle: -fontSize(0.3), // 起始角度，支持范围[0, 360]。
        clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: [fontSize(1.3) + '%', fontSize(1.4) + '%'],
        center: [fontSize(1.5) + '%', fontSize(1.8) + '%'], // 指示线的位置
        data: data,
        itemStyle: {
          opacity: 0
        }
      })
      return option
    },
    // 柱状图配置
    getChartRightConfig () {
      return {
        color: colors,
        title: {
          text: '近12个月收费情况',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontSize: '13px',
            fontWeight: 'normal'
          }
        },
        textStyle: { color: '#fff' },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter (params) {
            return params.reduce((res, item) => {
              const valueKey = item.dimensionNames[item.encode.y]
              res += `<br/><span style="display: inline-block; width: 100px;">${item.marker}${item.seriesName}</span><strong>${item.value[valueKey]}</strong>${item.seriesName === '回收率' ? '%' : '万元'}`
              return res
            }, `${params[0].axisValueLabel}`)
          }
        },
        legend: {
          bottom: 0,
          textStyle: { color: '#fff' },
          itemWidth: fontSize(0.4), // 矩形宽度
          itemHeight: fontSize(0.2) // 矩形高度
        },
        dataset: {
          dimension: ['month', 'total', 'receipts', 'rate'],
          source: this.rightSeriesData
        },
        xAxis: {
          type: 'category'
        },
        yAxis: [{
          name: '(万元)',
          type: 'value',
          position: 'left',
          splitLine: { show: false }
        }, {
          name: '(%)',
          type: 'value',
          position: 'right',
          splitLine: { show: false },
          min: 0,
          max: 100
        }],
        grid: {
          // left: 40,
          // top: 20,
          // right: 20
        },
        series: [
          {
            type: 'bar',
            name: '应收',
            encode: {
              x: 'month',
              y: 'total'
            },
            yAxisIndex: 0
          },
          {
            type: 'bar',
            name: '实收',
            encode: {
              x: 'month',
              y: 'receipts'
            },
            // stack: 'x',
            yAxisIndex: 0
          },
          {
            type: 'line',
            name: '回收率',
            encode: {
              x: 'month',
              y: 'rate'
            },
            smooth: true,
            yAxisIndex: 1
          }
        ]
      }
    },
    // 渲染图表
    renderEchart ({ ref, configName, chartName }) {
      const dom = this.$refs[ref]
      const config = this[configName]()
      this[chartName] = {
        option: config,
        chart: this.echarts.init(dom)
      }
      this[chartName].chart.setOption(config)
    },
    // 更新饼图tooltip
    updateLeftTooltipData () {
      if (!this.pageShowFixTooltip) {
        return // pageShowFixTooltip为false时，认为鼠标进入图层，则不在此更新数据
      } else if (!this.seriesData.length) {
        this.pageShowFixTooltip = false
        return
      }
      this.runDataIndex++
      if (this.runDataIndex >= this.seriesData.length) this.runDataIndex = 0
      const data = this.seriesData[this.runDataIndex]
      this.tooltipData = {
        value: data.value,
        seriesName: data.name,
        dataIndex: this.runDataIndex,
        percent: data.p
      }
    },
    // 更新柱状图的tooltip
    updateRightTooltip () {
      if (!this.rightShowTooltip || !this.rightSeriesData.length) return
      this.rightChart.chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.rightRunDataIndex
      })
      this.rightRunDataIndex++
      if (this.rightRunDataIndex >= 12) this.rightRunDataIndex = 0
    },
    // 鼠标进入的时候关闭tooltip，移出后再重新开启
    bindRightListen (myChart) {
      const that = this
      myChart.on('mouseover', function ({ dataIndex }) {
        that.rightShowTooltip = false
        that.rightRunDataIndex = dataIndex
      })
      myChart.on('globalout', function () {
        that.rightShowTooltip = true
      })
    },
    getApi (key) {
      return new Promise((resolve, reject) => {
        this.$get({ url: `/report/${key}` })
          .then(res => {
            if (!res) reject(res)
            else resolve(res.data)
          })
          .catch(reject)
      })
    },
    getData () {
      return new Promise((resolve, reject) => {
        this.loading = true
        Promise.all([
          this.getApi('chargingInfo'),
          this.getApi('lastTwelveMonthChargingInfo')
        ]).then(res => {
          this.loading = false
          const [chargingInfo, lastTwelveMonthChargingInfo] = res
          // 更新左图数据
          this.seriesData = chargingInfo.map(item => {
            item.value = item.total
            item.p = item.tp
            return item
          })
          this.pageShowFixTooltip = true
          // 更新右图数据
          this.rightSeriesData = lastTwelveMonthChargingInfo
          // 开始渲染图表
          this.renderEchart({ ref: 'chartLeft', configName: 'getChartLeftConfig', chartName: 'leftChart' })
          this.renderEchart({ ref: 'chartRight', configName: 'getChartRightConfig', chartName: 'rightChart' })
          this.$nextTick(() => {
            this.bindListen(this.leftChart.chart)
            this.bindRightListen(this.rightChart.chart)
          })
          resolve(res)
        }).catch(e => {
          this.loading = false
          reject(e)
        })
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="sass" scoped>
.chart-item
  display: flex
  flex-direction: row
  .org-chart
    width: 35%
    height: 100%
    position: relative
    .float-box
      position: absolute
      left: 10%
      top: 10%
  .center-box
    position: relative
    height: 100%
    width: 0
    .chart-radio
      position: absolute
      left: -70px
      top: 0
      width: 100px
      ::v-deep
        .el-radio-button__inner
          padding: 7px
          border-color: #178EF1
          background-color: unset
          color: #fff
        .el-radio-button__orig-radio:checked+.el-radio-button__inner
          background-color: #178EF1
  .month-chart
    width: 65%
    height: 100%
</style>
