<template lang='pug'>
.home-page.full-box
  .header
    .tip-texts
      .text-left
        i.iconfont(:class="`qi-${weather.icon || '-'}-fill`")
        span {{ weather.text || '-' }}
        span {{ `${weather.tempMin || ''}-${weather.tempMax || ''}` }}℃
        span |
        span {{ weather.precip || '-' }}mm
        span {{ weather.pressure || '-' }}hPa
      .text-right
        span {{ timeClock.datetime }}
        el-button.icon-button(
          type="text"
          icon="el-icon-switch-button"
          @click="handleBack")
    .title
  .main-content
    MainHeader(:data="headerData")
    MainCenter(
      ref="center"
      :data="chartData")
    MainFooter(
      ref="footer"
      :data="chartData")
</template>

<script>
import MainHeader from './components/MainHeader'
import MainCenter from './components/MainCenter'
import MainFooter from './components/MainFooter'
import TimeClock from '@/utils/timeClock.js'
import { getUserStorage } from '@/api/auth'

// 引入基本模板

import * as echarts from 'echarts'
import 'echarts-gl'
require('echarts/theme/macarons')
// const echarts = require('echarts/lib/echarts')

// 引入折线图等组件
// require('echarts/lib/chart/line')
// require('echarts/lib/chart/bar')
// require('echarts/lib/chart/pie')
// require('echarts/lib/chart/radar')

// // 引入提示框和title组件，图例
// require('echarts/lib/component/tooltip')
// require('echarts/lib/component/title')
// require('echarts/lib/component/legend')
// require('echarts/lib/component/dataset')
// require('echarts/lib/component/transform')
// require('echarts/lib/component/grid')
// require('echarts/lib/component/legendScroll') // 图例翻译滚动

// require('echarts/theme/macarons')

export default {
  name: 'Home',
  components: {
    MainHeader,
    MainCenter,
    MainFooter
  },
  provide () {
    return {
      echarts,
      getShowFixTooltip: () => {
        return this.showFixTooltip
      }
    }
  },
  data () {
    return {
      timeClock: { datetime: '' },
      weather: {},
      headerData: {},
      showFixTooltip: true,
      scrolltimer: null, // 自动滚动的定时任务
      chartData: {
        chargingInfo: [], // 收费情况饼图
        usenatureInfo: [], // 用水性质饼图
        usewaterInfo: [], // 用水占比饼图
        usenatureQuantity: [], // 用水性质用水量柱状图
        lastTwelveMonthChargingInfo: [], // 近十二个月收费情况柱状图
        lastTwelveMonthChargingTrend: [] // 近十二个月收费趋势折线图
      },
      source: {
        chargingInfo: '/report/chargingInfo', // 收费情况饼图
        lastTwelveMonthChargingInfo: '/report/lastTwelveMonthChargingInfo', // 近十二个月收费情况柱状图
        usenatureInfo: '/report/usenatureInfo', // 用水性质饼图
        usewaterInfo: '/report/usewaterInfo', // 用水占比饼图
        usenatureQuantity: '/report/usenatureQuantity', // 用水性质用水量柱状图
        lastTwelveMonthChargingTrend: '/report/lastTwelveMonthChargingTrend' // 近十二个月收费趋势折线图
      }
    }
  },
  methods: {
    // 获取天气信息
    getWeather () {
      return new Promise((resolve, reject) => {
        this.$get({ url: '/weather/getWeather' })
          .then(res => {
            if (!res) {
              reject(res)
              return res
            }
            this.weather = res.data
            resolve(res.data)
          })
          .catch(reject)
      })
    },
    // 获取头部块信息
    getHeaderData () {
      return new Promise((resolve, reject) => {
        this.$get({ url: '/report/firstRow' })
          .then(res => {
            if (!res) {
              reject(res)
              return res
            }
            this.headerData = res.data
            resolve(res.data)
          })
          .catch(reject)
      })
    },
    handleBack () {
      const authMenus = getUserStorage('menus')
      const path = authMenus[0]?.children?.[0]?.url
      this.$router.push(path || '/')
    },
    handlerScrollPromise () {
      return new Promise((resolve, reject) => {
        // 关闭窗口
        this.showFixTooltip = false
        setTimeout(() => {
          // 更新数据-向下广播
          this.$refs.center.updateTooltip()
          this.$refs.footer.updateTooltip()
          // this.runDataIndex++
          // if (this.runDataIndex >= this.seriesData.length) this.runDataIndex = 0
          // this.updateTooltipData(this.runDataIndex)
          // 开启窗口
          this.showFixTooltip = true
          resolve()
        }, 150)
      })
    },
    // 设置自动滚动
    autoScroll (stop) {
      /**
       * 关闭tooltip窗口-更新数据-再重新打开
       */
      if (stop) {
        // 再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
        window.clearInterval(this.scrolltimer)
      } else {
        this.scrolltimer = window.setInterval(async () => {
          await this.handlerScrollPromise()
        }, 3000) // 滚动速度
      }
    }
  },
  mounted () {
    this.timeClock = new TimeClock().start()
    this.getWeather()
    this.getHeaderData()
    // 更新数据-向下广播
    this.$refs.center.updateTooltip()
    this.$refs.footer.updateTooltip()
    this.autoScroll()
  },
  beforeDestroy () {
    this.showFixTooltip = false
    this.autoScroll(true)
    this.timeClock.end()
  }
}
</script>

<style lang='sass' scoped>
$text-box-margin: 18px // 左右外边距的值
$line-color: rgba(23, 142, 241, 0.7) // 斜线的颜色
$line-shadow: 0 -3px 8px rgba(23, 142, 241, 0.7) // 斜线的阴影

.line-box
  flex: 1
.home-title-box
  position: relative
.text-box
  // margin: 0 $text-box-margin // 使用变量设置左右外边距
  .label-box
    display: flex
    flex-direction: row
    align-items: center
    transform: translateY(-6px)
  .home-title-line
    position: absolute
    bottom: 0
</style>
